html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
}

*, *:before, *:after {
    box-sizing: inherit;
}